<template>
  <div class="Dyxpj1">
    <div class="left-box">
      <div class="box1">
        <div class="item1">精馏</div>
        <div>
          <div style="display: flex">
            <div class="item2">
              <div class="item2-box1">自控率</div>
              <div class="item2-box2">
                <div>
                  <div class="item-box21">回路名称</div>
                  <div class="item-box21">进料泵控制回路</div>
                  <div class="item-box21">电加热控制回路</div>
                  <div class="item-box21">残液泵控制回路</div>
                  <div class="item-box21">顶塔温度控制回路</div>
                  <div class="item-box21">冷凝器控制回路</div>
                  <div class="item-box21">产品泵控制回路</div>
                </div>
                <div>
                  <div class="item-box22">参与统计</div>
                  <div
                    v-for="(item, index) in itemList"
                    :key="index"
                    class="item-box22"
                  >
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1V[`DGZKL${index.id}`]
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1V[`DGZKL${item.id}`] ? "参与" : "不参与" }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="item-box22">投运状态</div>
                  <div class="item-box22">
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1S.BP101_SW
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1S.BP101_SW ? "自动" : "手动" }}
                    </div>
                  </div>
                  <div class="item-box22">
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1S.E101A_SW
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1S.E101A_SW ? "自动" : "手动" }}
                    </div>
                  </div>
                  <div class="item-box22">
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1S.BP103_SW
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1S.BP103_SW ? "自动" : "手动" }}
                    </div>
                  </div>
                  <div class="item-box22">
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1S.BP102_SW
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1S.BP102_SW ? "自动" : "手动" }}
                    </div>
                  </div>
                  <div class="item-box22">
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1S.FV105_SW
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1S.FV105_SW ? "自动" : "手动" }}
                    </div>
                  </div>
                  <div class="item-box22">
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1S.BP104_SW
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1S.BP104_SW ? "自动" : "手动" }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="item-box22">本班自控率</div>
                  <div
                    v-for="(item, index) in itemList"
                    :key="index"
                    class="item-box22"
                    @click="
                      toCompon(
                        2,
                        `ANZKL${item.id}`,
                        'DB1V',
                        `ANZKL${item.id}_JLT`,
                        `${item.name}自控率`
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V[`ANZKL${item.id}`],
                        `ANZKL${item.id}`,
                        'DB1V',
                        `ANZKL${item.id}_JLT`
                      )
                    "
                  >
                    {{ infoList.DB1V[`ANZKL${item.id}`] }}
                  </div>
                </div>
                <div>
                  <div class="item-box23">瞬时总自控率</div>
                  <div
                    class="item-box24"
                    @click="
                      toCompon(
                        2,
                        'ANZKL16_JLT',
                        'DB1V',
                        'ANZKL16',
                        '瞬时总自控率'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V.ANZKL16,
                        'ANZKL16_JLT',
                        'DB1V',
                        'ANZKL16'
                      )
                    "
                  >
                    {{ infoList.DB1V.ANZKL16 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="item3">
              <div class="item2-box1">稳定性</div>
              <div class="item2-box2">
                <div>
                  <div class="item-box22">精度</div>
                  <div
                    class="item-box22"
                    @click="
                      toCompon(
                        2,
                        'ANEV57',
                        'DB1V',
                        'ANEV57_JLT',
                        '进料泵控制回路精度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V.ANEV57,
                        'ANEV57',
                        'DB1V',
                        'ANEV57_JLT'
                      )
                    "
                  >
                    {{ infoList.DB1V.ANEV57 }}
                  </div>
                  <div class="item-box22"></div>
                  <div class="item-box22"></div>
                  <div
                    class="item-box22"
                    @click="
                      toCompon(
                        2,
                        'ANEV58',
                        'DB1V',
                        'ANEV58_JLT',
                        '塔顶温度控制回路精度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V.ANEV58,
                        'ANEV58',
                        'DB1V',
                        'ANEV58_JLT'
                      )
                    "
                  >
                    {{ infoList.DB1V.ANEV58 }}
                  </div>
                  <div
                    class="item-box22"
                    @click="
                      toCompon(
                        2,
                        'ANEV59',
                        'DB1V',
                        'ANEV59_JLT',
                        '冷凝水控制回路精度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V.ANEV59,
                        'ANEV59',
                        'DB1V',
                        'ANEV59_JLT'
                      )
                    "
                  >
                    {{ infoList.DB1V.ANEV59 }}
                  </div>
                  <div
                    class="item-box22"
                    @click="
                      toCompon(
                        2,
                        'ANEV60',
                        'DB1V',
                        'ANEV60_JLT',
                        '产品泵控制回路精度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V.ANEV60,
                        'ANEV60',
                        'DB1V',
                        'ANEV60_JLT'
                      )
                    "
                  >
                    {{ infoList.DB1V.ANEV60 }}
                  </div>
                </div>
                <div>
                  <div class="item-box22">稳定性</div>
                  <div
                    class="item-box22"
                    @click="
                      toCompon(
                        2,
                        'ANEV25',
                        'DB1V',
                        'ANEV25_JLT',
                        '进料泵控制回路稳定性'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V.ANEV25,
                        'ANEV25',
                        'DB1V',
                        'ANEV25_JLT'
                      )
                    "
                  >
                    {{ infoList.DB1V.ANEV25 }}
                  </div>
                  <div class="item-box22"></div>
                  <div class="item-box22"></div>
                  <div
                    class="item-box22"
                    @click="
                      toCompon(
                        2,
                        'ANEV28',
                        'DB1V',
                        'ANEV28_JLT',
                        '塔顶温度控制回路稳定性'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V.ANEV28,
                        'ANEV28_JLT',
                        'DB1V',
                        'ANEV28'
                      )
                    "
                  >
                    {{ infoList.DB1V.ANEV28 }}
                  </div>
                  <div
                    class="item-box22"
                    @click="
                      toCompon(
                        2,
                        'ANEV31',
                        'DB1V',
                        'ANEV31_JLT',
                        '冷凝水控制回路稳定性'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V.ANEV31,
                        'ANEV31',
                        'DB1V',
                        'ANEV31_JLT'
                      )
                    "
                  >
                    {{ infoList.DB1V.ANEV31 }}
                  </div>
                  <div
                    class="item-box22"
                    @click="
                      toCompon(
                        2,
                        'ANEV34',
                        'DB1V',
                        'ANEV34_JLT',
                        '产品泵控制回路稳定性'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V.ANEV34,
                        'ANEV34',
                        'DB1V',
                        'ANEV34_JLT'
                      )
                    "
                  >
                    {{ infoList.DB1V.ANEV34 }}
                  </div>
                </div>

                <div>
                  <div class="item-box23">瞬时总稳定性</div>
                  <div
                    class="item-box24"
                    @click="
                      toCompon(
                        2,
                        'ANZKL21',
                        'DB1V',
                        'ANZKL21_JLT',
                        '瞬时总稳定性'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V.ANZKL21,
                        'ANZKL21',
                        'DB1V',
                        'ANZKL21_JLT'
                      )
                    "
                  >
                    {{ infoList.DB1V.ANZKL21 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="item4">
              <div class="item4-1">经济性</div>
              <div class="item-box22">经济性</div>
              <div class="item-box22"></div>
              <div class="item-box22"></div>
              <div
                class="item-box22"
                @click="toCompon(2, 'ANZKL22', 'DB1V', 'ANZKL22_JLT', '经济性')"
                @dblclick="
                  Cclick(
                    infoList.DB1V.ANZKL22,
                    'ANZKL22',
                    'DB1V',
                    'ANZKL22_JLT'
                  )
                "
              >
                {{ infoList.DB1V.ANZKL22 }}
              </div>
              <div
                class="item-box22"
                @click="toCompon(2, 'AN226', 'DB1V', 'AN226_JLT', '经济性')"
                @dblclick="
                  Cclick(infoList.DB1V.AN226, 'AN226', 'DB1V', 'AN226_JLT')
                "
              >
                {{ infoList.DB1V.AN226 }}
              </div>
              <div class="item-box22"></div>
              <div class="item-box22"></div>
            </div>
          </div>
          <div class="item_botom">
            <div class="PJ-LABLE">综合评价</div>
            <div class="PJ1 jiange">A</div>
            <div class="PJ1 jiange1">A</div>
            <div class="PJ1 jiange1">A</div>
            <div class="pjval jiange1"></div>
            <div class="pjval jiange1"></div>
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="item1">反应釜</div>
        <div>
          <div style="display: flex">
            <div class="item2">
              <div class="item2-box2">
                <div>
                  <div class="item-box21">2#原料罐电加热</div>
                  <div class="item-box21">反应釜电加热</div>
                  <div class="item-box21">反应釜冷却水阀</div>
                  <div class="item-box21">冷凝器冷却水阀</div>
                  <div class="item-box21">反应釜物料阀</div>
                  <div class="item-box21">反应釜搅拌器</div>
                  <div class="item-box21">1#原料罐出口调节</div>
                  <div class="item-box21">回流阀</div>
                  <div class="item-box21">采出阀</div>
                  <div class="item-box21">2#原料罐出口调节</div>
                </div>
                <div>
                  <div
                    class="item-box22"
                    v-for="(item, index) in itemListzkl"
                    :key="index"
                  >
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1V[`DGZKL${index.id}`]
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1V[`DGZKL${item.id}`] ? "参与" : "不参与" }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="item-box22">
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1S.TZ401_SW
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1S.TZ401_SW ? "自动" : "手动" }}
                    </div>
                  </div>
                  <div class="item-box22">
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1S.TZ402_SW
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1S.TZ402_SW ? "自动" : "手动" }}
                    </div>
                  </div>
                  <div class="item-box22">
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1S.FV406_SW
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1S.FV406_SW ? "自动" : "手动" }}
                    </div>
                  </div>
                  <div class="item-box22">
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1S.FV405_SW
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1S.FV405_SW ? "自动" : "手动" }}
                    </div>
                  </div>
                  <div class="item-box22">
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1S.BP401_SW
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1S.BP401_SW ? "自动" : "手动" }}
                    </div>
                  </div>
                  <div class="item-box22">
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1S.BP402_SW
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1S.BP402_SW ? "自动" : "手动" }}
                    </div>
                  </div>
                  <div class="item-box22">
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1S.FV401_SW
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1S.FV401_SW ? "自动" : "手动" }}
                    </div>
                  </div>
                  <div class="item-box22">
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1S.FV403_SW
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1S.FV403_SW ? "自动" : "手动" }}
                    </div>
                  </div>
                  <div class="item-box22">
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1S.FV404_SW
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1S.FV404_SW ? "自动" : "手动" }}
                    </div>
                  </div>
                  <div class="item-box22">
                    <div
                      class="item-box22-2"
                      :style="{
                        backgroundColor: infoList.DB1S.FV402_SW
                          ? 'green'
                          : 'red',
                      }"
                    >
                      {{ infoList.DB1S.FV402_SW ? "自动" : "手动" }}
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    class="item-box22"
                    v-for="(item, index) in itemListzkl1"
                    :key="index"
                    @click="
                      toCompon(
                        2,
                        `ANZKL${item.id}`,
                       'DB1V',                    
                        `ANZKL${item.id}_JTL`,
                        `${item.name}自控率`
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V[`ANZKL${item.id}`],
                        `ANZKL${item.id}`,
                        'DB1V',
                        `ANZKL${item.id}_JTL`
                      )
                    "
                  >
                    {{ infoList.DB1V[`ANZKL${item.id}`] }}
                  </div>
                  <div
                    class="item-box22"
                    v-for="(item) in itemListzkl2"
                    :key="item.id"
                    @click="
                      toCompon(
                        2,
                        `ANZKL${item.id}`,
                        'DB1V',
                        `ANZKL${item.id}_JTL`,
                        `${item.name}自控率`
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V[`ANZKL${item.id}`],
                        `ANZKL${item.id}`,
                        'DB1V',
                        `ANZKL${item.id}_JTL`
                      )
                    "
                  >
                    {{ infoList.DB1V[`ANZKL${item.id}`] }}
                  </div>
                </div>
                <div>
                  <div
                    class="item-box23"
                    @click="
                      toCompon(
                        2,
                        'ANZKL35',
                        'DB1V',
                        'ANZKL35_JLT',
                        '瞬时总自控率'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V.ANZKL35,
                        'ANZKL35',
                        'DB1V',
                        'ANZKL35_JLT'
                      )
                    "
                  >
                    {{ infoList.DB1V.ANZKL35 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="item3">
              <div class="item2-box2">
                <div>
                  <div
                    class="item-box22"
                    @click="toCompon(2, 'ANEV56', 'DB1V', 'ANEV56_JLT', '精度')"
                    @dblclick="
                      Cclick(
                        infoList.DB1V.ANEV56,
                        'ANEV56',
                        'DB1V',
                        'ANEV56_JLT'
                      )
                    "
                  >
                    {{ infoList.DB1V.ANEV56 }}
                  </div>
                </div>
                <div>
                  <div
                    class="item-box22"
                    @click="
                      toCompon(2, 'ANEV56', 'DB1V', 'ANEV56_JLT', '稳定性')
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V.ANEV56,
                        'ANEV56',
                        'DB1V',
                        'ANEV56_JLT'
                      )
                    "
                  >
                    {{ infoList.DB1V.ANEV56 }}
                  </div>
                </div>

                <div>
                  <div
                    class="item-box23"
                    @click="
                      toCompon(2, 'ANEV56', 'DB1V', 'ANEV56_JLT', '瞬时总稳定性')
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V.ANEV56,
                        'ANEV56',
                        'DB1V',
                        'ANEV56_JLT'
                      )
                    "
                  >
                    {{ infoList.DB1V.ANEV56 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="item4">
              <div class="item-box22"  @click="
                      toCompon(2, 'ANEV56', 'DB1V', 'ANEV56_JLT', '经济性')
                    "
                    @dblclick="
                      Cclick(
                        infoList.DB1V.ANEV56,
                        'ANEV56',
                        'DB1V',
                        'ANEV56_JLT'
                      )
                    "
                  >
                    {{ infoList.DB1V.ANEV56 }}</div>
            </div>
          </div>
          <div class="item_botom">
            <div class="PJ-LABLE">综合评价</div>
            <div class="PJ1 jiange">A</div>
            <div class="PJ1 jiange1">A</div>
            <div class="PJ1 jiange1">A</div>
            <div class="pjval jiange1"></div>
            <div class="pjval jiange1"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div class="bt">自定义考核</div>
      <div class="biaoge">
        <div class="bg-hang1">
          <div class="bg-item1">设定时长</div>
          <div class="bg-item2">
            <div class="val1"> {{ infoList.PUB.ZDYKH_TSP }}</div>
            <div class="anniu jiange">停止考核</div>
          </div>
        </div>
        <div class="bg-hang1">
          <div class="bg-item1">已考核时长</div>
          <div class="bg-item2">
            <div class="val"> {{ infoList.PUB.ZDYKH_TRUN }}</div>
          </div>
        </div>
        <div class="bg-hang1">
          <div class="bg-item1">开始时间</div>
          <div class="bg-item2">
            <div class="val">考核开始时间</div>
          </div>
        </div>
        <div class="bg-hang1">
          <div class="bg-item1">结束时间</div>
          <div class="bg-item2">
            <div class="val"></div>
          </div>
        </div>
        <div class="bg-hang1">
          <div class="bg-item1">自定义考核状态</div>
          <div class="bg-item2">
            <div class="val">{{ infoList.PUB.ZDYKH_TRU?"正在考核":"等待考核" }}</div>
          </div>
        </div>
      </div>
      <div class="bt jiange1">系统时钟设定</div>
      <div class="biaoge2">
        <div class="bg-hang1">
          <div class="bg-item1">名称</div>
          <div class="bg-item2">
            <div class="val">状态与设定</div>
          </div>
        </div>
        <div class="bg-hang1">
          <div class="bg-item1">当前控制器时钟</div>
          <div class="bg-item2">
            <div class="val"></div>
          </div>
        </div>
        <div class="bg-hang1">
          <div class="bg-item1">交班时间1设定</div>
          <div class="bg-item2">
            <div class="val">交班时间2设定</div>
          </div>
        </div>
        <div class="bg-hang1">
          <div class="bg-item1">交班时间3设定</div>
          <div class="bg-item2">
            <div class="val"></div>
          </div>
        </div>
        <div class="bg-hang1">
          <div class="bg-item1">日复位时间设定</div>
          <div class="bg-item2">
            <div class="val"></div>
          </div>
        </div>
        <div class="bg-hang1">
          <div class="bg-item1">月复位时间设定</div>
          <div class="bg-item2">
            <div class="val"></div>
          </div>
        </div>
      </div>
    </div>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
  </div>
</template>
<script>
import index from "./_index/index.js";
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  components: {
    Historical,
  },
  data() {
    return {
      chName: "",
      maskbok: false,
      JRLcysz: false,
      isMshow: false,
      isHshow: false,
      Aname: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      canshu1: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      flag: 0,
      isComShow: false,
      fullscreenLoading: false,
      grouptime: null,
      spotArr: [],
      projectData: "",
      itemList: [
        {
          id: "01",
          name: "进料泵控制回路",
          node: "DB1V",
        },
        {
          id: "02",
          name: "电加热控制回路",
          node: "DB1V",
        },
        {
          id: "03",
          name: "残液泵控制回路",
          node: "DB1V",
        },
        {
          id: "04",
          name: "塔顶温度控制回路",
          node: "DB1V",
        },
        {
          id: "05",
          name: "冷凝水控制回路",
          node: "DB1V",
        },
        {
          id: "06",
          name: "产品泵控制回路",
          node: "DB1V",
        },
      ],
      itemListzkl: [
        {
          id: "10",
          name: "进料泵控制回路自控率",
        },
        {
          id: "11",
          name: "电加热控制回路自控率",
        },
        {
          id: "12",
          node: "DB1V",
        },
        {
          id: "13",
          name: "塔顶温度控制回路自控率",
        },
        {
          id: "14",
          name: "冷凝水控制回路自控率",
        },
        {
          id: "15",
          name: "产品泵控制回路自控率",
        },
        {
          id: "16",
          name: "1#原料罐出口调节",
        },
        {
          id: "17",
          name: "回流阀",
        },
        {
          id: "18",
          name: "采出阀",
        },
        {
          id: "19",
          name: "2#原料罐出口调节",
        },
      ],
      itemListzkl1: [
        {
          id: "10",
          name: "进料泵控制回路自控率",
        },
        {
          id: "11",
          name: "电加热控制回路自控率",
        },
        {
          id: "12",
          node: "DB1V",
        },
        {
          id: "13",
          name: "塔顶温度控制回路自控率",
        },
        {
          id: "14",
          name: "冷凝水控制回路自控率",
        },
        {
          id: "15",
          name: "产品泵控制回路自控率",
        },
      ],
      itemListzkl2: [
        {
          id: "40",
          name: "1#原料罐出口调节",
        },
        {
          id: "41",
          name: "回流阀",
        },
        {
          id: "42",
          name: "采出阀",
        },
        {
          id: "43",
          name: "2#原料罐出口调节",
        },
      ],
    };
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  mixins: [index],
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
  },
};
</script>
<style scoped lang="scss">
.Dyxpj1 {
  display: flex;
  .left-box {
    width: 65vw;
    height: 77vh;
    border: 1px solid #537284;
    margin-top: 4vh;
    margin-left: 2vw;
  }
  .box1 {
    height: 35vh;
    width: 65vw;
    border: 1px solid #537284;
    border-right: none;
    border-left: none;
    border-top: none;
    display: flex;
    .item1 {
      width: 7vw;
      height: 35vh;
      line-height: 35vh;
      color: #ffff;
      text-align: center;
      border-right: 1px solid #537284;
      font-size: 1.1vw;
    }
    .item2 {
      width: 30.5vw;
      height: 28.8vh;
      border-right: 1px solid #537284;
      .item2-box1 {
        width: 30.5vw;
        height: 4vh;
        border-bottom: 1px solid #537284;
        text-align: center;
        color: #fff;
      }
      .item2-box2 {
        width: 30.5vw;
        display: flex;
        .item-box21 {
          width: 8vw;
          // height: 3.2vh;
          line-height: 3.4vh;
          border-right: 1px solid #537284;
          border-bottom: 1px solid #537284;
          color: #8aeaff;
          text-align: center;
        }
        .item-box22 {
          width: 5vw;
          height: 3.4vh;
          line-height: 3.4vh;
          border-right: 1px solid #537284;
          border-bottom: 1px solid #537284;
          color: #8aeaff;
          display: flex;
          justify-content: center; /* 左右居中 */
          align-items: center; /* 上下居中 */
          cursor: pointer;
        }
        .item-box22-2 {
          width: 4vw;
          height: 2.8vh;
          background-color: red;
          text-align: center;
          line-height: 2.8vh;
          color: #fff;
        }
        .item-box23 {
          width: 7.2vw;
          height: 3.4vh;
          line-height: 3.4vh;
          // border-right: 1px solid #537284;
          border-bottom: 1px solid #537284;
          color: #8aeaff;
          display: flex;
          justify-content: center; /* 左右居中 */
          align-items: center; /* 上下居中 */
        }
        .item-box24 {
          width: 7.2vw;
          height: 21vh;
          line-height: 21vh;
          // border-right: 1px solid #537284;
          border-bottom: 1px solid #537284;
          color: #8aeaff;
          display: flex;
          justify-content: center; /* 左右居中 */
          align-items: center; /* 上下居中 */
        }
      }
      .item2-box3 {
        height: 6.2vh;
        width: 30.5vw;
        border-right: 1px solid #537284;
        display: flex;
        display: flex;
        // justify-content: center; /* 左右居中 */
        align-items: center; /* 上下居中 */
        .item-box21 {
          width: 8vw;
          // height: 3.2vh;
          line-height: 6.2vh;
          border-right: 1px solid #537284;
          // border-bottom: 1px solid #537284;
          color: #8aeaff;
          text-align: center;
        }
        .PJ1 {
          width: 2vw;
          height: 4vh;
          line-height: 4vh;
          background-color: #266978;
          text-align: center;
        }
        .jiange {
          margin-left: 1vw;
        }
        .jiange1 {
          margin-left: 0.2vw;
        }
        .pjval {
          margin-left: 4vw;
          color: #fff;
        }
      }
    }
    .item3 {
      width: 20vw;
      height: 28.8vh;
      border-right: 1px solid #537284;
      .item2-box1 {
        width: 20vw;
        height: 4vh;
        border-bottom: 1px solid #537284;
        text-align: center;
        color: #fff;
      }
      .item2-box2 {
        width: 30.5vw;
        display: flex;
        .item-box21 {
          width: 8vw;
          // height: 3.2vh;
          line-height: 3.4vh;
          border-right: 1px solid #537284;
          border-bottom: 1px solid #537284;
          color: #8aeaff;
          text-align: center;
        }
        .item-box22 {
          width: 6vw;
          height: 3.4vh;
          line-height: 3.4vh;
          border-right: 1px solid #537284;
          border-bottom: 1px solid #537284;
          color: #8aeaff;
          display: flex;
          justify-content: center; /* 左右居中 */
          align-items: center; /* 上下居中 */
          cursor: pointer;
        }
        .item-box22-2 {
          width: 4vw;
          height: 2.8vh;
          background-color: red;
          text-align: center;
          line-height: 2.8vh;
          color: #fff;
        }
        .item-box23 {
          width: 8vw;
          line-height: 3.4vh;
          // border-right: 1px solid #537284;
          border-bottom: 1px solid #537284;
          color: #8aeaff;
          display: flex;
          justify-content: center; /* 左右居中 */
          align-items: center; /* 上下居中 */
        }
        .item-box24 {
          width: 8vw;
          height: 21vh;
          line-height: 21vh;
          // border-right: 1px solid #537284;
          border-bottom: 1px solid #537284;
          color: #8aeaff;
          display: flex;
          justify-content: center; /* 左右居中 */
          align-items: center; /* 上下居中 */
          cursor: pointer;
        }
      }
      .item2-box3 {
        height: 6.2vh;
        width: 30.5vw;
        border-right: 1px solid #537284;
        display: flex;
        display: flex;
        // justify-content: center; /* 左右居中 */
        align-items: center; /* 上下居中 */
        .item-box21 {
          width: 8vw;
          // height: 3.2vh;
          line-height: 6.2vh;
          border-right: 1px solid #537284;
          // border-bottom: 1px solid #537284;
          color: #8aeaff;
          text-align: center;
        }
      }
    }
    .item4 {
      .item4-1 {
        width: 7.3vw;
        // height: 3.2vh;
        line-height: 4vh;
        // border-right: 1px solid #537284;
        border-bottom: 1px solid #537284;
        color: #8aeaff;
        text-align: center;
        color: #fff;
      }
      .item-box22 {
        width: 7.2vw;
        height: 3.4vh;
        line-height: 3.4vh;
        // border-right: 1px solid #537284;
        border-bottom: 1px solid #537284;
        color: #8aeaff;
        display: flex;
        justify-content: center; /* 左右居中 */
        align-items: center; /* 上下居中 */
      }
    }
    .item_botom {
      width: 50.5vw;
      height: 6.2vh;
      line-height: 6.2vh;
      display: flex;
      align-items: center; /* 上下居中 */
      .PJ-LABLE {
        color: #8aeaff;
        width: 8vw;
        text-align: center;
        border-right: 1px solid #537284;
      }
      .PJ1 {
        width: 2vw;
        height: 4vh;
        line-height: 4vh;
        background-color: #266978;
        text-align: center;
        color: #fff;
      }
      .jiange {
        margin-left: 1vw;
      }
      .jiange1 {
        margin-left: 0.2vw;
      }
      .pjval {
        margin-left: 4vw;
        color: #fff;
      }
    }
  }
  .box2 {
    height: 43vh;
    width: 65vw;
    // border: 1px solid #537284;
    border-right: none;
    display: flex;
    .item1 {
      width: 7vw;
      height: 42vh;
      line-height: 42vh;
      color: #ffff;
      text-align: center;
      border-right: 1px solid #537284;
      font-size: 1.1vw;
    }

    .item2 {
      width: 30.5vw;
      height: 35vh;
      border-right: 1px solid #537284;
      .item2-box1 {
        width: 30.5vw;
        height: 4vh;
        border-bottom: 1px solid #537284;
        text-align: center;
        color: #fff;
      }
      .item2-box2 {
        width: 30.5vw;
        display: flex;
        .item-box21 {
          width: 8vw;
          // height: 3.2vh;
          line-height: 3.4vh;
          border-right: 1px solid #537284;
          border-bottom: 1px solid #537284;
          color: #8aeaff;
          text-align: center;
        }
        .item-box22 {
          width: 5vw;
          height: 3.4vh;
          line-height: 3.4vh;
          border-right: 1px solid #537284;
          border-bottom: 1px solid #537284;
          color: #8aeaff;
          display: flex;
          justify-content: center; /* 左右居中 */
          align-items: center; /* 上下居中 */
          cursor: pointer;
        }
        .item-box22-2 {
          width: 4vw;
          height: 2.8vh;
          background-color: red;
          text-align: center;
          line-height: 2.8vh;
          color: #fff;
        }
        .item-box23 {
          width: 7.2vw;
          height: 35vh;
          line-height: 35vh;
          // border-right: 1px solid #537284;
          border-bottom: 1px solid #537284;
          color: #8aeaff;
          display: flex;
          justify-content: center; /* 左右居中 */
          align-items: center; /* 上下居中 */
          cursor: pointer;
        }
        .item-box24 {
          width: 7.2vw;
          height: 31.5vh;
          line-height: 31.5vh;
          // border-right: 1px solid #537284;
          border-bottom: 1px solid #537284;
          color: #8aeaff;
          display: flex;
          justify-content: center; /* 左右居中 */
          align-items: center; /* 上下居中 */
          cursor: pointer;
        }
      }
      .item2-box3 {
        height: 6.2vh;
        width: 30.5vw;
        border-right: 1px solid #537284;
        display: flex;
        display: flex;
        // justify-content: center; /* 左右居中 */
        align-items: center; /* 上下居中 */
        .item-box21 {
          width: 8vw;
          // height: 3.2vh;
          line-height: 6.2vh;
          border-right: 1px solid #537284;
          // border-bottom: 1px solid #537284;
          color: #8aeaff;
          text-align: center;
        }
        .PJ1 {
          width: 2vw;
          height: 4vh;
          line-height: 4vh;
          background-color: #266978;
          text-align: center;
        }
        .jiange {
          margin-left: 1vw;
        }
        .jiange1 {
          margin-left: 0.2vw;
        }
        .pjval {
          margin-left: 4vw;
          color: #fff;
        }
      }
    }
    .item3 {
      width: 20vw;
      height: 35vh;
      border-right: 1px solid #537284;
      .item2-box1 {
        width: 20vw;
        height: 4vh;
        border-bottom: 1px solid #537284;
        text-align: center;
        color: #fff;
      }
      .item2-box2 {
        width: 30.5vw;
        display: flex;
        .item-box21 {
          width: 8vw;
          // height: 3.2vh;
          line-height: 3.4vh;
          border-right: 1px solid #537284;
          border-bottom: 1px solid #537284;
          color: #8aeaff;
          text-align: center;
        }
        .item-box22 {
          width: 6vw;
          height: 35vh;
          line-height: 35vh;
          border-right: 1px solid #537284;
          border-bottom: 1px solid #537284;
          color: #8aeaff;
          display: flex;
          justify-content: center; /* 左右居中 */
          align-items: center; /* 上下居中 */
          cursor: pointer;
        }
        .item-box22-2 {
          width: 4vw;
          height: 2.8vh;
          background-color: red;
          text-align: center;
          line-height: 2.8vh;
          color: #fff;
        }
        .item-box23 {
          width: 8vw;
          height: 35vh;
          line-height: 35vh;
          // border-right: 1px solid #537284;
          border-bottom: 1px solid #537284;
          color: #8aeaff;
          display: flex;
          justify-content: center; /* 左右居中 */
          align-items: center; /* 上下居中 */
          cursor: pointer;
        }
        .item-box24 {
          width: 8vw;
          height: 21vh;
          line-height: 21vh;
          // border-right: 1px solid #537284;
          border-bottom: 1px solid #537284;
          color: #8aeaff;
          display: flex;
          justify-content: center; /* 左右居中 */
          align-items: center; /* 上下居中 */
          cursor: pointer;
        }
      }
      .item2-box3 {
        height: 6.2vh;
        width: 30.5vw;
        border-right: 1px solid #537284;
        display: flex;
        display: flex;
        // justify-content: center; /* 左右居中 */
        align-items: center; /* 上下居中 */
        .item-box21 {
          width: 8vw;
          // height: 3.2vh;
          line-height: 6.2vh;
          border-right: 1px solid #537284;
          // border-bottom: 1px solid #537284;
          color: #8aeaff;
          text-align: center;
          cursor: pointer;
        }
      }
    }
    .item4 {
      .item4-1 {
        width: 7.3vw;
        // height: 3.2vh;
        line-height: 4vh;
        // border-right: 1px solid #537284;
        border-bottom: 1px solid #537284;
        color: #8aeaff;
        text-align: center;
        color: #fff;
      }
      .item-box22 {
        width: 7.2vw;
        height: 35vh;
        line-height: 35vh;
        // border-right: 1px solid #537284;
        border-bottom: 1px solid #537284;
        color: #8aeaff;
        display: flex;
        justify-content: center; /* 左右居中 */
        align-items: center; /* 上下居中 */
        cursor: pointer;
      }
    }
    .item_botom {
      width: 50.5vw;
      height: 6.2vh;
      line-height: 6.2vh;
      display: flex;
      align-items: center; /* 上下居中 */
      .PJ-LABLE {
        color: #8aeaff;
        width: 8vw;
        text-align: center;
        border-right: 1px solid #537284;
      }
      .PJ1 {
        width: 2vw;
        height: 4vh;
        line-height: 4vh;
        background-color: #266978;
        text-align: center;
        color: #fff;
      }
      .jiange {
        margin-left: 1vw;
      }
      .jiange1 {
        margin-left: 0.2vw;
      }
      .pjval {
        margin-left: 4vw;
        color: #fff;
      }
    }
  }
  .right-box {
    width: 29vw;
    height: 77vh;
    margin-left: 2vw;
    margin-top: 4vh;
    .bt {
      color: #fff;
      font-size: 1.1vw;
      width: 29vw;
      text-align: center;
      height: 4vh;
      line-height: 4vh;
    }
    .biaoge {
      width: 29vw;
      height: 20.5vh;
      border: 1px solid #537284;
      border-bottom: none;
    }
    .bg-hang1 {
      display: flex;
      border-bottom: 1px solid #537284;
      justify-items: center;
    }
    .bg-item1 {
      width: 10vw;
      height: 4vh;
      line-height: 4vh;
      color: #8aeaff;
      text-align: center;
      border-right: 1px solid #537284;
    }
    .bg-item2 {
      width: 19vw;
      height: 4vh;
      line-height: 4vh;
      display: flex;
      align-items: center;

      .val {
        width: 19vw;
        text-align: center;
        color: #fff;
      }
      .val1 {
        width: 10.5vw;
        text-align: right;
        color: #8aeaff;
      }
      .anniu {
        width: 5vw;
        color: #fff;
        background-color: red;
        height: 3vh;
        line-height: 3vh;
        text-align: center;
      }
      .jiange {
        margin-left: 2vw;
      }
    }
    .biaoge2 {
      width: 29vw;
      height: 24.5vh;
      border: 1px solid #537284;
      border-bottom: none;
    }
    .jiange1 {
      margin-top: 6vh;
    }
  }
}
</style>